import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IS_ENABLE_GLOBAL_LOADER } from 'shared/config';
import { BaseApiQueryType, ExtraOptionType } from 'shared/models';
import { setLoading } from '../slices';

export const pause = (stop: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, stop);
  });

export const baseQuery = (options: ExtraOptionType) => {
  return fetchBaseQuery({
    fetchFn: async (...args) => {
      await pause(200);
      return fetch(...args);
    },
    prepareHeaders: (header, { getState }) => {
      const { authorizationApi = true } = options;

      if (!authorizationApi) return header;

      const {
        authSlice: { accessToken },
      } = getState() as any;

      header.set('Authorization', `Bearer ${accessToken}`);
      return header;
    },
  });
};

export const baseApiQuery: BaseApiQueryType = async (args, api, extraOption = {}) => {
  const { enableLoading = IS_ENABLE_GLOBAL_LOADER } = extraOption;
  const apiCopy = { ...api };
  const { dispatch } = apiCopy;
  // signal.onabort(() => {

  // })
  try {
    if (enableLoading) dispatch(setLoading(true));
    const query = baseQuery(extraOption);
    const result = await query(args, api, extraOption);
    return result;
  } catch (error: unknown) {
    throw error;
  } finally {
    if (enableLoading) dispatch(setLoading(false));
  }
};
