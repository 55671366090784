import { AuthType, LoginType, RegisterType } from 'shared/models';
import { albumService } from '../services';

const api = albumService.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<AuthType, LoginType>({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body,
      }),
      extraOptions: {
        authorizationApi: false,
      },
    }),
    signup: build.mutation<RegisterType, RegisterType>({
      query: (body) => ({
        url: '/register',
        method: 'POST',
        body,
      }),
      extraOptions: {
        authorizationApi: false,
      },
    }),
  }),
});

export const { useLoginMutation, useSignupMutation } = api;
