import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Pagination, Select, Stack, SelectChangeEvent } from '@mui/material';

export const CustomPagination = () => {
  const [age, setAge] = useState('');
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };
  return (
    <div className="pagination-main-wrapper w-100 mt-10">
      <div className="d-flex justify-content-between align-items-center">
        <div className="item-per-page-wrapper">
          <div>
            <div className="filter-dropdown">
              <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-filled-label">Items Per Page</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={age}
                  onChange={handleChange}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="pagination-content">
          <Stack spacing={2}>
            <Pagination count={10} />
          </Stack>
        </div>
      </div>
    </div>
  );
};
