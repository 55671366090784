import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuthType } from 'shared/models';

// const initialState: AuthType = {
//   accessToken: JSON.parse(localStorage.getItem('user') || '{"accessToken":null,"user":null}').accessToken,
//   user: JSON.parse(localStorage.getItem('user') || '{"accessToken":null,"user":null}').user,
// };

const initialState: AuthType = {
  accessToken: null,
  user: null,
};

export const slice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    logout: (state, action: PayloadAction<AuthType>) => {
      state.accessToken = null;
      state.user = null;
    },
    setToken: (state, action: PayloadAction<AuthType>) => {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
    },
  },
});

export const { logout, setToken } = slice.actions;
export default slice;
