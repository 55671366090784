import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApiQuery } from '../interceptor';

export const albumService = createApi({
  reducerPath: 'album',
  baseQuery: (...args) => {
    const baseUrl = process.env.REACT_APP_NATURE_TOURS_SERVICE as string;
    args[0].url = `${baseUrl}${args[0].url}`;
    return baseApiQuery(...args);
  },
  endpoints: () => ({}),
});

export const postService = createApi({
  reducerPath: 'post',
  baseQuery: (...args) => {
    const baseUrl = process.env.REACT_APP_JSON_PLACE_HOLDER_SERVICE as string;
    args[0].url = `${baseUrl}${args[0].url}`;
    return baseApiQuery(...args);
  },
  endpoints: () => ({}),
});
