export const CONFIGURATION_FIELDS = {
  TREATMENT_SITE: 'treatmentSite',
  TREATMENT_INTENT: 'treatmentIntent',
  TREATMENT_MODALITY: 'treatmentModality',
  IMRT_MEDICAL_NECESSITY: 'imrtMedicalNecessity',
  TREATMENT_LOCATION: 'treatmentLocation',
  TREATMENT_MACHINE: 'treatmentMachine',
  PATIENT_POSITION: 'patientPosition',
  ARM_POSITION: 'armPosition',
  LEG_POSITION: 'legPosition',
  IMMOBILIZATION_DEVICE: 'immobilizationDevice',
  ADDITIONAL_INSTRUCTIONS: 'additionalInstructions',
  IMAGING_INFORMATION: 'imagingInformation',
  FUSION: 'fusion',
  SPECIAL_TREATMENT_PROCEDURE: 'specialTreatmentProcedure',
  SPECIAL_MEDICAL_PHYSICS_CONSULT: 'specialMedicalPhysicsConsult',
};
