import { postService } from '../services';

const api = postService.enhanceEndpoints({ addTagTypes: ['Post'] }).injectEndpoints({
  endpoints(builder) {
    return {
      fetchPosts: builder.query({
        providesTags: ['Post'],
        query: () => {
          return {
            url: '/posts',
            method: 'GET',
          };
        },
      }),
    };
  },
});

export const { useFetchPostsQuery } = api;
export default api;
