import { AlbumsType, MemberType } from 'shared/models';
import { albumService } from '../services';

const api = albumService.enhanceEndpoints({ addTagTypes: ['Albums'] }).injectEndpoints({
  endpoints(builder) {
    return {
      fetchAlbums: builder.query<AlbumsType[], MemberType>({
        providesTags: (result, error, member) => {
          return [{ type: 'Albums', id: member.id }];
        },
        query: (member: MemberType) => {
          return {
            url: '/albums',
            method: 'GET',
            params: {
              urd: member.id,
            },
          };
        },
      }),
      addAlbums: builder.mutation({
        invalidatesTags: (result, error, member) => {
          return [{ type: 'Albums', id: member.urd }];
        },
        query: (body: AlbumsType) => {
          return {
            url: '/albums',
            method: 'POST',
            body,
          };
        },
      }),
      deleteAlbums: builder.mutation({
        // invalidatesTags: (result, error, member) => {
        //   return [{ type: 'Albums', id: member.userId }];
        // },
        query: (body: AlbumsType) => {
          return {
            url: `/albums/${body.id}`,
            mode: 'cors',
            cache: 'no-cache',
            method: 'DELETE',
          };
        },
        extraOptions: {
          service: 'NATURE_TOURS_SERVICE',
          enableLoading: true,
        },
      }),
    };
  },
});

export const { useFetchAlbumsQuery, useAddAlbumsMutation, useDeleteAlbumsMutation } = api;
