import React, { FC } from 'react';
import { useAppSelector } from 'shared/rtk';

export const Loader: FC = () => {
  const { isLoading } = useAppSelector((state) => state.loading);
  return (
    <>
      {!isLoading ? null : (
        <div id="loader-wrapper">
          <div id="loader" />

          {/* <div className="loader-section section-left" />
      <div className="loader-section section-right" /> */}
        </div>
      )}
    </>
  );
};

export const Preloader: FC = () => {
  return (
    <div id="loader-wrapper">
      <div id="loader" />
    </div>
  );
};
