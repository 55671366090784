interface MenuItemType {
  itemValue: string;
  itemLabel: string;
  [key: string]: string | number;
}

// patient-tracker form dropdown options
export const physicianOrders: MenuItemType[] = [
  {
    itemValue: 'complete',
    itemLabel: 'Complete',
  },
  {
    itemValue: 'incomplete',
    itemLabel: 'Incomplete',
  },
];

// export const nursingTasks: MenuItemType[] = [
//   {
//     itemValue: 'complete',
//     itemLabel: 'Complete',
//   },
//   {
//     itemValue: 'incomplete',
//     itemLabel: 'Incomplete',
//   },
// ];

export const preTreatmentWorkup: MenuItemType[] = [
  {
    itemValue: 'complete',
    itemLabel: 'Complete',
  },
  {
    itemValue: 'incomplete',
    itemLabel: 'Incomplete',
  },
];

export const preSimulationVisit: MenuItemType[] = [
  {
    itemValue: 'scheduled',
    itemLabel: 'Scheduled',
  },
  {
    itemValue: 'not needed',
    itemLabel: 'Not needed',
  },
  {
    itemValue: 'completed',
    itemLabel: 'Completed',
  },
];

export const simulationStatus: MenuItemType[] = [
  {
    itemValue: 'scheduled',
    itemLabel: 'Scheduled',
  },
  {
    itemValue: 'not scheduled',
    itemLabel: 'Not scheduled',
  },
  {
    itemValue: 'completed',
    itemLabel: 'Completed',
  },
];

// simulation create page form
export const treatmentSite: MenuItemType[] = [
  {
    itemValue: 'CNS',
    itemLabel: 'CNS',
  },
  {
    itemValue: 'Anus',
    itemLabel: 'Anus',
  },
  {
    itemValue: 'Breast',
    itemLabel: 'Breast',
  },
  {
    itemValue: 'Esophagus',
    itemLabel: 'Esophagus',
  },
  {
    itemValue: 'Gastric',
    itemLabel: 'Gastric',
  },
  {
    itemValue: 'Gyn',
    itemLabel: 'Gyn',
  },
  {
    itemValue: 'Head and Neck',
    itemLabel: 'Head and Neck',
  },
  {
    itemValue: 'Hepatobiliary',
    itemLabel: 'Hepatobiliary',
  },
  {
    itemValue: 'Lung',
    itemLabel: 'Lung',
  },
  {
    itemValue: 'Lymphoma',
    itemLabel: 'Lymphoma',
  },
  {
    itemValue: 'Other - GI',
    itemLabel: 'Other - GI',
  },
  {
    itemValue: 'Other - Palliative',
    itemLabel: 'Other - Palliative',
  },
  {
    itemValue: 'Other - Thoracic',
    itemLabel: 'Other - Thoracic',
  },
  {
    itemValue: 'Palliative - Bone',
    itemLabel: 'Palliative - Bone',
  },
  {
    itemValue: 'Pancreas',
    itemLabel: 'Pancreas',
  },
  {
    itemValue: 'Rectum',
    itemLabel: 'Rectum',
  },
  {
    itemValue: 'Skin',
    itemLabel: 'Skin',
  },
  {
    itemValue: 'Prostate',
    itemLabel: 'Prostate',
  },
  {
    itemValue: 'Other - Genitourinary',
    itemLabel: 'Other - Genitourinary',
  },
];

export const treatmentIntent: MenuItemType[] = [
  {
    itemValue: 'Adjuvant curative with concurrent chemo',
    itemLabel: 'Adjuvant curative with concurrent chemo',
  },
  {
    itemValue: 'Adjuvant curative without concurrent chemo',
    itemLabel: 'Adjuvant curative without concurrent chemo',
  },
  {
    itemValue: 'Definitive (curative) with concurrent chemo',
    itemLabel: 'Definitive (curative) with concurrent chemo',
  },
  {
    itemValue: 'Definitive (curative) without concurrent chemo',
    itemLabel: 'Definitive (curative) without concurrent chemo',
  },
  {
    itemValue: 'Intraoperative',
    itemLabel: 'Intraoperative',
  },
  {
    itemValue: 'Neoadjuvant curative with concurrent chemo',
    itemLabel: 'Neoadjuvant curative with concurrent chemo',
  },
  {
    itemValue: 'Neoadjuvant curative without concurrent chemo',
    itemLabel: 'Neoadjuvant curative without concurrent chemo',
  },
  {
    itemValue: 'Palliative (aggressive)',
    itemLabel: 'Palliative (aggressive)',
  },
  {
    itemValue: 'Palliative (non-aggressive)',
    itemLabel: 'Palliative (non-aggressive)',
  },
  {
    itemValue: 'Palliative with chemotherapy',
    itemLabel: 'Palliative with chemotherapy',
  },
  {
    itemValue: 'Preoperative',
    itemLabel: 'Preoperative',
  },
  {
    itemValue: 'Postoperative',
    itemLabel: 'Postoperative',
  },
  {
    itemValue: 'Prophylactic',
    itemLabel: 'Prophylactic',
  },
  {
    itemValue: 'Prophylactic cranial irradiation (PCI)',
    itemLabel: 'Prophylactic cranial irradiation (PCI)',
  },
  {
    itemValue: 'Prophylactic heterotopic ossification (HO)',
    itemLabel: 'Prophylactic heterotopic ossification (HO)',
  },
  {
    itemValue: 'Radical',
    itemLabel: 'Radical',
  },
  {
    itemValue: 'Salvage',
    itemLabel: 'Salvage',
  },
  {
    itemValue: 'Treatment of benign disease',
    itemLabel: 'Treatment of benign disease',
  },
  {
    itemValue: '***',
    itemLabel: '***',
  },
];

export const treatmentModality: MenuItemType[] = [
  {
    itemValue: '3D Conformal',
    itemLabel: '3D Conformal',
  },
  {
    itemValue: 'IMRT',
    itemLabel: 'IMRT',
  },
  {
    itemValue: 'IGRT-IMRT',
    itemLabel: 'IGRT-IMRT',
  },
  {
    itemValue: 'Stereotactic - Fraction(s)',
    itemLabel: 'Stereotactic - Fraction(s)',
  },
  {
    itemValue: 'Brachytherapy',
    itemLabel: 'Brachytherapy',
  },
  {
    itemValue: 'Electrons',
    itemLabel: 'Electrons',
  },
];

export const IMRTMedicalNecessity: MenuItemType[] = [
  {
    itemValue: 'The target is irregularly shaped and in close proximity to critical structures, specifically the ***',
    itemLabel: 'The target is irregularly shaped and in close proximity to critical structures, specifically the ***',
  },
  {
    itemValue: 'IMRT is the only option to cover the volume of interest with narrow margins and protect immediately ',
    itemLabel: 'IMRT is the only option to cover the volume of interest with narrow margins and protect immediately ',
  },
  {
    itemValue: 'Only IMRT can produce an acceptable dose distribution while sparing the ***',
    itemLabel: 'Only IMRT can produce an acceptable dose distribution while sparing the ***',
  },
  {
    itemValue: '***',
    itemLabel: '***',
  },
];

export const chemotherapy: MenuItemType[] = [
  {
    itemValue: 'chemo to start day 1 of radiation',
    itemLabel: 'chemo to start day 1 of radiation',
  },
  {
    itemValue: 'chemo to start during week 1 of radiation',
    itemLabel: 'chemo to start during week 1 of radiation',
  },
  {
    itemValue: 'chemo to start per medical oncologist',
    itemLabel: 'chemo to start per medical oncologist',
  },
];

export const treatmentLocation: MenuItemType[] = [
  {
    itemValue: 'Downtown campus',
    itemLabel: 'Downtown campus',
  },
  {
    itemValue: 'Midtown campus',
    itemLabel: 'Midtown campus',
  },
  {
    itemValue: 'Southside campus',
    itemLabel: 'Southside campus',
  },
  {
    itemValue: 'Northside campus',
    itemLabel: 'Northside campus',
  },
  {
    itemValue: 'Hill Country campus',
    itemLabel: 'Hill Country campus',
  },
];

export const treatmentMachine: MenuItemType[] = [
  {
    itemValue: 'True Bream 1',
    itemLabel: 'True Bream 1',
  },
  {
    itemValue: 'View Ray',
    itemLabel: 'View Ray',
  },
  {
    itemValue: 'Protons',
    itemLabel: 'Protons',
  },
  {
    itemValue: 'True Beam 2',
    itemLabel: 'True Beam 2',
  },
  {
    itemValue: 'True Beam 3',
    itemLabel: 'True Beam 3',
  },
];

export const prescriptionFrequency: MenuItemType[] = [
  {
    itemValue: 'Daily',
    itemLabel: 'Daily',
  },
  {
    itemValue: 'Every Other Day',
    itemLabel: 'Every Other Day',
  },
  {
    itemValue: 'Twice a Day',
    itemLabel: 'Twice a Day',
  },
  {
    itemValue: 'Once a Week',
    itemLabel: 'Once a Week',
  },
];

export const structureDropdown1: MenuItemType[] = [
  {
    itemValue: 'V',
    itemLabel: 'V',
  },
  {
    itemValue: 'Mean less than',
    itemLabel: 'Mean less than',
  },
  {
    itemValue: 'Max less than',
    itemLabel: 'Max less than',
  },
];

export const primaryGoalDropdown1: MenuItemType[] = [
  {
    itemValue: '%rx',
    itemLabel: '%rx',
  },
  {
    itemValue: 'cGy',
    itemLabel: 'cGy',
  },
];

export const primaryGoalDropdown2: MenuItemType[] = [
  {
    itemValue: '>',
    itemLabel: '>',
  },
  {
    itemValue: '<',
    itemLabel: '<',
  },
];

export const primaryGoalDropdown3: MenuItemType[] = [
  {
    itemValue: '%',
    itemLabel: '%',
  },
  {
    itemValue: 'cc',
    itemLabel: 'cc',
  },
];

export const patientPosition: MenuItemType[] = [
  {
    itemValue: 'Supine',
    itemLabel: 'Supine',
  },
  {
    itemValue: 'Prone',
    itemLabel: 'Prone',
  },
  {
    itemValue: 'Rt. Decub',
    itemLabel: 'Rt. Decub',
  },
  {
    itemValue: 'Lt. Decub',
    itemLabel: 'Lt. Decub',
  },
  {
    itemValue: '***',
    itemLabel: '***',
  },
];

export const armPosition: MenuItemType[] = [
  {
    itemValue: 'Arms at sides',
    itemLabel: 'Arms at sides',
  },
  {
    itemValue: 'Arms at abdomen',
    itemLabel: 'Arms at abdomen',
  },
  {
    itemValue: 'Arms on chest',
    itemLabel: 'Arms on chest',
  },
  {
    itemValue: 'Arms akimbo',
    itemLabel: 'Arms akimbo',
  },
  {
    itemValue: 'Arms up',
    itemLabel: 'Arms up',
  },
  {
    itemValue: '***',
    itemLabel: '***',
  },
];

export const legPosition: MenuItemType[] = [
  {
    itemValue: 'Straight',
    itemLabel: 'Straight',
  },
  {
    itemValue: 'Frog legged',
    itemLabel: 'Frog legged',
  },
  {
    itemValue: '***',
    itemLabel: '***',
  },
];

export const imagingType: MenuItemType[] = [
  {
    itemValue: 'Daily kV is requested',
    itemLabel: 'Daily kV is requested',
  },
  {
    itemValue: 'Dailiy CBCT is requested',
    itemLabel: 'Dailiy CBCT is requested',
  },
  {
    itemValue: 'Daily kV and CBCT is requested',
    itemLabel: 'Daily kV and CBCT is requested',
  },
  {
    itemValue: 'Daily ExacTrac is requested',
    itemLabel: 'Daily ExacTrac is requested',
  },
  {
    itemValue: 'Daily kV, CBCT, and ExacTrac is requested',
    itemLabel: 'Daily kV, CBCT, and ExacTrac is requested',
  },
  {
    itemValue: 'Daily CBCT and ExacTrac is requested',
    itemLabel: 'Daily CBCT and ExacTrac is requested',
  },
  {
    itemValue: 'Daily kV and ExacTrac is requested',
    itemLabel: 'Daily kV and ExacTrac is requested',
  },
  {
    itemValue: 'kV VSim Only and MV every 5 fractions is requested',
    itemLabel: 'kV VSim Only and MV every 5 fractions is requested',
  },
];

export const align: MenuItemType[] = [
  {
    itemValue: 'soft tissues',
    itemLabel: 'soft tissues',
  },
  {
    itemValue: 'bony anatomy',
    itemLabel: 'bony anatomy',
  },
];

export const scan: MenuItemType[] = [
  {
    itemValue: 'MRI',
    itemLabel: 'MRI',
  },
  {
    itemValue: 'PET',
    itemLabel: 'PET',
  },
];

export const imageSequence: MenuItemType[] = [
  {
    itemValue: 'T1',
    itemLabel: 'T1',
  },
  {
    itemValue: 'T2',
    itemLabel: 'T2',
  },
];
export const reasonReSimulation: MenuItemType[] = [
  {
    itemValue: 'Boost',
    itemLabel: 'Boost',
  },
  {
    itemValue: 'Adapting Mid Treatment',
    itemLabel: 'Adapting Mid Treatment',
  },
];
export const medicalNecessity: MenuItemType[] = [
  {
    itemValue: 'The patient is to get concurrent chemotherapy',
    itemLabel: 'The patient is to get concurrent chemotherapy',
  },
  {
    itemValue: 'The patient will receive radiotherapy to 3 or more sites',
    itemLabel: 'The patient will receive radiotherapy to 3 or more sites',
  },
  {
    itemValue: 'The patient will receive radiotherapy to a previously treated site',
    itemLabel: 'The patient will receive radiotherapy to a previously treated site',
  },
  {
    itemValue: 'The patient will receive hyperfractionation with BID treatments',
    itemLabel: 'The patient will receive hyperfractionation with BID treatments',
  },
  {
    itemValue: 'The patient has an anatomic anomaly which will require additional planning time and effort',
    itemLabel: 'The patient has an anatomic anomaly which will require additional planning time and effort',
  },
  {
    itemValue: 'Treatment planning will require extensive staff time and effort',
    itemLabel: 'Treatment planning will require extensive staff time and effort',
  },
];
export const medicalPhysics: MenuItemType[] = [
  {
    itemValue: 'The patient has a defibillator and/or pacemaker which requires OSL measurement on day 1',
    itemLabel: 'The patient has a defibillator and/or pacemaker which requires OSL measurement on day 1',
  },
  {
    itemValue:
      'The patient is to get stereotactic radiosurgery, requiring fusion of diagnostic reference imaging, complex treatment planning, and QA prior to treatment delivery.',
    itemLabel:
      'The patient is to get stereotactic radiosurgery, requiring fusion of diagnostic reference imaging, complex treatment planning, and QA prior to treatment delivery.',
  },
  {
    itemValue:
      'The patient is to get HDR brachytherapy, which will require simulation, complex treatment planning with catheter reconstruction and treatment delivery.',
    itemLabel:
      'The patient is to get HDR brachytherapy, which will require simulation, complex treatment planning with catheter reconstruction and treatment delivery.',
  },
  {
    itemValue: 'Other',
    itemLabel: 'Other',
  },
];
export const dosimetryStatus: MenuItemType[] = [
  { itemLabel: 'Complete', itemValue: 'Complete' },
  { itemLabel: 'Incomplete', itemValue: 'Incomplete' },
];
export const physician: MenuItemType[] = [
  { itemLabel: 'Dr. A H Thompson', itemValue: 'Dr. A HThompson' },
  { itemLabel: 'Dr. S G Cooper', itemValue: 'Dr. S G Cooper' },
  { itemLabel: 'Dr. R H Radhe', itemValue: 'Dr. R H Radhe' },
  { itemLabel: 'Dr. C J Williams', itemValue: 'Dr. C J Williams' },
  { itemLabel: 'Dr. D A Wong', itemValue: 'Dr. D A Wong' },
  { itemLabel: 'Martha Miller', itemValue: 'Martha Miller' },
  { itemLabel: 'C Yang', itemValue: 'C Yang' },
  { itemLabel: 'E Fredrick', itemValue: 'E Fredrick' },
  { itemLabel: 'A Buffay', itemValue: 'A Buffay' },
  { itemLabel: 'C Donald', itemValue: 'C Donald' },
];
export const sequence: MenuItemType[] = [
  { itemLabel: '1', itemValue: '1' },
  { itemLabel: '2', itemValue: '2' },
  { itemLabel: '3', itemValue: '3' },
  { itemLabel: '4', itemValue: '4' },
  { itemLabel: '5', itemValue: '5' },
];
export const followup: MenuItemType[] = [
  { itemLabel: '1 Week', itemValue: '1 week' },
  { itemLabel: '2 Weeks', itemValue: '2 weeks' },
  { itemLabel: '3 Weeks', itemValue: '3 weeks' },
  { itemLabel: '4 Weeks', itemValue: '4 weeks' },
  { itemLabel: '5 Weeks', itemValue: '5 weeks' },
  { itemLabel: '6 Weeks', itemValue: '6 weeks' },
  { itemLabel: '2 Months', itemValue: '2 months' },
  { itemLabel: '3 Months', itemValue: '3 months' },
  { itemLabel: '4 Months', itemValue: '4 months' },
  { itemLabel: '5 Months', itemValue: '5 months' },
  { itemLabel: '6 Months', itemValue: '6 months' },
  { itemLabel: '1 Year', itemValue: '1 year' },
  { itemLabel: 'Other', itemValue: 'other' },
];
export const typeOfStudies: MenuItemType[] = [
  { itemLabel: 'With contrast', itemValue: 'With contrast' },
  { itemLabel: 'Without contrast', itemValue: 'Without contrast' },
  { itemLabel: 'With and Without contrast', itemValue: 'With and Without contrast' },
];
