import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
};

export const slice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading } = slice.actions;

export default slice;
