import React, { FC, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { UIRoutes } from 'shared/constant';
import deleteBlueIcon from '../assets/images/svg-images/delete-blue-icon.svg';
import modalClose from '../assets/images/svg-images/modal-close-icon.svg';
import notificationIcon from '../assets/images/svg-images/notification-icon.svg';
import sidebarToggle from '../assets/images/svg-images/sidebar-toggle-icon.svg';
import viewAction from '../assets/images/svg-images/view-action-icon.svg';

interface SidebarProps {
  toggleHandler: () => void;
}

const Header: FC<SidebarProps> = (props) => {
  const [openPanel, setOpenPanel] = useState(false);
  const sidePanleHandler = () => {
    setOpenPanel(!openPanel);
  };
  const { toggleHandler } = props;

  return (
    <>
      <div className={`header-panel-main-wrapper${openPanel ? ' open-sidebar-panel' : ''}`}>
        <div className="header-panel d-flex align-items-center">
          <div className="toggle-panel pr-10" onClick={toggleHandler} onKeyDown={toggleHandler}>
            <div>
              <img src={sidebarToggle} alt="toggle-icon" className="toggle-icon" />
            </div>
          </div>
          <div className="header-inner-panel">
            <div className="header-left-panel">
              {/* <div className="breadcrumb-panel d-flex align-items-center">
              <div className={`breadcrumb-item ${activePath1.moduleName}-item pl-20`}>
                <span className="font-600 path-name">{activePath1 && activePath1.moduleName}</span>
              </div>
              <div className={activePath2 ? 'progress-bar-section' : 'd-none'}> </div>

              {activePath2 && (
                <div className="breadcrumb-item active pl-20">
                  <span className="breadcrumb-text">{activePath2.moduleName}</span>
                </div>
              )}
            </div> */}
              <div className="breadcrumb-panel d-flex align-items-center">
                <NavLink to={UIRoutes.PRE_TREATMENT.INDEX} className="breadcrumb-item pre-treatment-item">
                  <div>
                    <span className="breadcrumb-text">Pre-Treatment</span>
                  </div>
                </NavLink>
                <NavLink
                  to={UIRoutes.TREATMENT_PLANNING.INDEX}
                  className="breadcrumb-item treatment-planning-item d-flex align-items-center "
                >
                  <div className="progress-bar-section" />
                  <div className="">
                    <span className="breadcrumb-text">Treatment Planning</span>
                  </div>
                </NavLink>
                <NavLink
                  to={UIRoutes.ON_TREATMENT.INDEX}
                  className="d-flex align-items-center breadcrumb-item on-treatment-item"
                >
                  <div className="progress-bar-section" />
                  <div className="">
                    <span className="breadcrumb-text">On Treatment</span>
                  </div>
                </NavLink>
                <NavLink
                  to={UIRoutes.END_TREATMENT.INDEX}
                  className="d-flex align-items-center breadcrumb-item end-treatment-item"
                >
                  <div className="progress-bar-section" />
                  <div className="">
                    <span className="breadcrumb-text">End of Treatment</span>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="header-right-panel">
              {/* <div className="search-panel">
              <Input label="" name="email" value="" placeholder="Search Here" />
              <div className="search-block">
                <img src={ImageAssets.headerIcons.searchIcon} alt="app-logo" className="search-icon" />
              </div>
            </div> */}
              <Tooltip arrow title="Notification">
                <div className="notification-panel ml-20" onClick={sidePanleHandler} onKeyDown={sidePanleHandler}>
                  <img src={notificationIcon} alt="app-logo" />
                </div>
              </Tooltip>
            </div>
            <div className="simulation-history-wrapper notification-main-wrapper p-0 mb-0">
              <div className="history-close-wrapper">
                <div className="d-flex justify-content-end" onClick={sidePanleHandler} onKeyDown={sidePanleHandler}>
                  <img src={modalClose} alt="back-icon" className="back-icon" />
                </div>
              </div>
              <div className="history-heading-wrapper">
                <div>
                  <span className="text-white font-600"> Notification</span>
                </div>
              </div>
              <div className="history-body-wrapper">
                <div className="history-list">
                  <div className="history-block">
                    <div className="history-day-block">
                      <div>
                        <span className="text-gray font-14 italic-text">Latest</span>
                      </div>
                    </div>
                    <div className="history-details-block">
                      <div className="history-single-block d-flex justify-content-between">
                        <div>
                          <div className="mb--5">
                            <span className="font-500">Simulation Request</span>
                          </div>
                          <div className="mb--5">
                            <span className="italic-text text-gray">A new task is assigned</span>
                          </div>
                          <div className="history-user-block">
                            <span className="text-gray">05 Apr 2023, 12:00 PM</span>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="mr-10">
                            <Tooltip arrow title="View">
                              <img src={viewAction} alt="viewActionIcon" />
                            </Tooltip>
                          </div>
                          <div>
                            <Tooltip arrow title="Delete">
                              <img src={deleteBlueIcon} alt="deleteBlueIcon" />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="history-details-block">
                      <div className="history-single-block d-flex justify-content-between">
                        <div>
                          <div className="mb--5">
                            <span className="font-500">Patients</span>
                          </div>
                          <div className="mb--5">
                            <span className="italic-text text-gray">New patient added</span>
                          </div>
                          <div className="history-user-block">
                            <span className="text-gray"> 05 Apr 2023, 02:00 PM</span>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="mr-10">
                            <Tooltip arrow title="View">
                              <img src={viewAction} alt="viewActionIcon" />
                            </Tooltip>
                          </div>
                          <div>
                            <Tooltip arrow title="Delete">
                              <img src={deleteBlueIcon} alt="deleteBlueIcon" />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="history-details-block">
                      <div className="history-single-block d-flex justify-content-between">
                        <div>
                          <div className="mb--5">
                            <span className="font-500">Pre- Treatment</span>
                          </div>
                          <div className="mb--5">
                            <span className="italic-text text-gray">An appointment is scheduled</span>
                          </div>
                          <div className="history-user-block">
                            <span className="text-gray">05 Apr 2023, 01:00 PM</span>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="mr-10">
                            <Tooltip arrow title="View">
                              <img src={viewAction} alt="viewActionIcon" />
                            </Tooltip>
                          </div>
                          <div>
                            <Tooltip arrow title="Delete">
                              <img src={deleteBlueIcon} alt="deleteBlueIcon" />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="history-block">
                    <div className="history-day-block">
                      <div>
                        <span className="text-gray font-14 italic-text">Yesterday</span>
                      </div>
                    </div>
                    <div className="history-details-block">
                      <div className="history-single-block d-flex justify-content-between">
                        <div>
                          <div className="mb--5">
                            <span className="font-500">Patient</span>
                          </div>
                          <div className="mb--5">
                            <span className="italic-text text-gray">New patient added </span>
                          </div>
                          <div className="history-user-block">
                            <span className="text-gray">04 Apr 2023, 12:00 PM</span>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="mr-10">
                            <Tooltip arrow title="View">
                              <img src={viewAction} alt="viewActionIcon" />
                            </Tooltip>
                          </div>
                          <div>
                            <Tooltip arrow title="Delete">
                              <img src={deleteBlueIcon} alt="deleteBlueIcon" />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="history-details-block">
                      <div className="history-single-block d-flex justify-content-between">
                        <div>
                          <div className="mb--5">
                            <span className="font-500">Simulation Request</span>
                          </div>
                          <div className="mb--5">
                            <span className="italic-text text-gray">A new task is assigned</span>
                          </div>
                          <div className="history-user-block">
                            <span className="text-gray">04 Apr 2023, 12:00 PM</span>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="mr-10">
                            <Tooltip arrow title="View">
                              <img src={viewAction} alt="viewActionIcon" />
                            </Tooltip>
                          </div>
                          <div>
                            <Tooltip arrow title="Delete">
                              <img src={deleteBlueIcon} alt="deleteBlueIcon" />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
