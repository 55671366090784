import React, { FC, useMemo, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { UIRoutes } from 'shared/constant';
import profileImage from '../assets/images/login-user-img.png';
import { customRoute, routes } from '../routes';

interface SidebarProps {
  isCollapse: boolean;
}

const Sidebar: FC<SidebarProps> = (props) => {
  const { isCollapse } = props;
  const [openDropdown, setOpenDropdown] = useState(false);
  // const [openSubDropdown, setOpenSubDropdown] = useState(false);
  const [openProfileDropdown, setOpenProfileDropdown] = useState(false);
  const menus = useMemo(() => routes.filter((el: customRoute) => el.isDisplayInSidebar), []);
  // console.log('menu :>> ', menus);

  const dropdownHandler = () => {
    setOpenDropdown(!openDropdown);
  };
  // const navigate = useNavigate();
  // const dropdownSubHandler = () => {
  //   setOpenSubDropdown(!openSubDropdown);
  //   navigate(`${UIRoutes.PRE_TREATMENT.INDEX}`);
  // };

  const profileDropdownHandler = () => {
    setOpenProfileDropdown(!openProfileDropdown);
  };

  return (
    <>
      <div className={`sidebar-panel${isCollapse ? ' active-sidebar-panel' : ''}`}>
        <div className="sidebar-logo-panel">
          <div className="logo-btn" />
        </div>
        <div className="navbar-panel">
          <ul className="sidebar-nav-items scrollable-panel">
            {menus.map((el: customRoute) => (
              <li className="nav-item" key={el.id} id={el.id}>
                <Tooltip arrow title={isCollapse ? '' : el.title}>
                  <NavLink to={el.path}>
                    <span className={`nav-icon-block ${el.title.toLowerCase().replace(/\s/g, '')}-icon-block`} />
                    <span className="nav-item-text">{el.title}</span>
                  </NavLink>
                </Tooltip>
              </li>
            ))}
          </ul>
          {/* <ul className="sidebar-nav-items scrollable-panel">
            <li className="nav-item">
              <Tooltip arrow title={isCollapse ? '' : 'Dashboard'}>
                <NavLink to={UIRoutes.DASHBOARD}>
                  <span className="nav-icon-block dashboard-icon-block" />
                  <span className="nav-item-text">Dashboard</span>
                </NavLink>
              </Tooltip>
            </li>
            <li className="nav-item">
              <Tooltip arrow title={isCollapse ? '' : 'Worklist'}>
                <NavLink to={UIRoutes.WORK_LIST.INDEX}>
                  <span className="nav-icon-block worklist-icon-block" />
                  <span className="nav-item-text">Worklist</span>
                </NavLink>
              </Tooltip>
            </li>
            <li className="nav-item">
              <Tooltip arrow title={isCollapse ? '' : 'Patients'}>
                <NavLink to={UIRoutes.PATIENT.INDEX}>
                  <span className="nav-icon-block patients-icon-block" />
                  <span className="nav-item-text">Patients</span>
                </NavLink>
              </Tooltip>
            </li>
            <li className="nav-item">
              <Tooltip arrow title={isCollapse ? '' : 'Pre Treatment'}>
                <NavLink to={UIRoutes.PRE_TREATMENT.INDEX}>
                  <span className="nav-icon-block preTreatment-icon-block" />
                  <span className="nav-item-text">Pre Treatment</span>
                </NavLink>
              </Tooltip>
            </li>
            <li className="nav-item">
              <Tooltip arrow title={isCollapse ? '' : 'Treatment Panning'}>
                <NavLink to={UIRoutes.TREATMENT_PLANNING.INDEX}>
                  <span className="nav-icon-block treatPlanning-icon-block" />
                  <span className="nav-item-text">Treatment Panning </span>
                </NavLink>
              </Tooltip>
            </li>
            <li className="nav-item">
              <Tooltip arrow title={isCollapse ? '' : 'Chart'}>
                <NavLink to={UIRoutes.CHART.INDEX}>
                  <span className="nav-icon-block chart-icon-block" />
                  <span className="nav-item-text">Chart </span>
                </NavLink>
              </Tooltip>
            </li>
            <li className="nav-item">
              <Tooltip arrow title={isCollapse ? '' : 'On Treatment'}>
                <NavLink to={UIRoutes.ON_TREATMENT.INDEX}>
                  <span className="nav-icon-block onTreatment-icon-block" />
                  <span className="nav-item-text">On Treatment</span>
                </NavLink>
              </Tooltip>
            </li>
            <li className="nav-item">
              <Tooltip arrow title={isCollapse ? '' : 'End Treatment'}>
                <NavLink to={UIRoutes.END_TREATMENT.INDEX}>
                  <span className="nav-icon-block endTreatment-icon-block" />
                  <span className="nav-item-text">End Treatment</span>
                </NavLink>
              </Tooltip>
            </li> */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          {/* <li className="nav-item">
              <Tooltip arrow title={isCollapse ? '' : 'Nursing'}>
                <NavLink to={UIRoutes.COMING_SOON.INDEX}>
                  <span className="nav-icon-block nursing-icon-block" />
                  <span className="nav-item-text">Nursing</span>
                </NavLink>
              </Tooltip>
            </li> */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          {/* <li className="nav-item">
              <Tooltip arrow title={isCollapse ? '' : 'Prior Authorization'}>
                <NavLink to={UIRoutes.COMING_SOON.INDEX}>
                  <span className="nav-icon-block authorization-icon-block" />
                  <span className="nav-item-text">Prior Authorization</span>
                </NavLink>
              </Tooltip>
            </li> */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          {/* <li className="nav-item">
              <Tooltip arrow title={isCollapse ? '' : 'Configuration'}>
                <NavLink to={`${UIRoutes.CONFIGURATION.INDEX}/${UIRoutes.CONFIGURATION.SIMULATION_ORDER}`}>
                  <span className="nav-icon-block configuration-icon-block" />
                  <span className="nav-item-text">Configuration</span>
                </NavLink>
              </Tooltip>
            </li> */}
          {/* <li className={`nav-item dropdown-item${openDropdown ? ' open-submenu' : ''}`}>
              <Tooltip arrow title={isCollapse ? '' : 'Configuration'}>
                <button type="button" onClick={dropdownHandler} onKeyDown={dropdownHandler}>
                  <span className="nav-icon-block configuration-icon-block" />
                  <span className="nav-item-text">Configuration</span>
                </button>
              </Tooltip>
              <ul className="nav-submenu">
                <li className={`submenu-nav-item ${moduleName === 'Clinical Information' && 'active-item'}`}>
                  <Tooltip arrow title={isCollapse ? '' : 'Clinical Information'}>
                    <a href="/" className="submenu-nav-text">
                      <span className="nav-icon-block clinical-info-sub-block" />
                      <span className="nav-item-text">Clinical Information</span>
                    </a>
                  </Tooltip>
                </li>
                <li className={`submenu-nav-item ${moduleName === 'Simulation Order' && 'active-item'}`}>
                  <Tooltip arrow title={isCollapse ? '' : 'Simulation Order'}>
                    <Link
                      to={`${UIRoutes.CONFIGURATION.INDEX}/${UIRoutes.CONFIGURATION.SIMULATION_ORDER}`}
                      className="submenu-nav-text"
                    >
                      <span className="nav-icon-block sim-order-sub-block" />
                      <span className="nav-item-text">Simulation Order</span>
                    </Link>
                  </Tooltip>
                </li>
              </ul>
            </li> */}
          {/* </ul> */}
        </div>
        <div className={`login-user-panel${openProfileDropdown ? ' open-profile-menu' : ''}`}>
          <div className="center" onClick={profileDropdownHandler} onKeyDown={profileDropdownHandler}>
            <div className="user-img-block">
              <img src={profileImage} alt="app-logo" className="img-fluid login-user-img" />
            </div>
            <div className="user-name-block">
              <span className="user-name">Hi Smith </span>
            </div>
          </div>
          <ul className="nav-profile-menu">
            <li className="profile-details d-flex align-items-center">
              <span>
                <img src={profileImage} alt="user" className="profile-img" />
              </span>
              <span>
                <span className="font-14 font-500">Dr.John Doe</span>
                <span>Radiation Oncologists</span>
              </span>
            </li>
            <li className="submenu-nav-item">
              <Link to={UIRoutes.USER_PROFILE.INDEX} className="submenu-nav-text">
                <span className="nav-icon-block myProfile-block" />
                <span className="nav-item-text">My profile</span>
              </Link>
            </li>
            <li className="submenu-nav-item">
              <Link to="/" className="submenu-nav-text">
                <span className="nav-icon-block password-block" />
                <span className="nav-item-text">Change Password</span>
              </Link>
            </li>
            <li className="submenu-nav-item">
              <Link to="/" className="submenu-nav-text">
                <span className="nav-icon-block logout-block" />
                <span className="nav-item-text">Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
