import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { albumService, postService } from './api';
import { loadingSlice, authSlice } from './slices';

export const store = configureStore({
  reducer: {
    [loadingSlice.name]: loadingSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [albumService.reducerPath]: albumService.reducer,
    [postService.reducerPath]: postService.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(albumService.middleware, postService.middleware);
  },
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export * from './api';
export * from './slices';
