import React, { FC } from 'react';
import { Box } from '@mui/material';

interface ITabPanel {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: ITabPanel) => {
  const { children, value, index, ...rest } = props;
  //   console.log('props :>> ', props);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
};
