import React, { FC, Suspense, lazy, ReactNode, Fragment, useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Preloader } from 'components/common';
import { UIRoutes } from 'shared/constant';
import Layout from '../layouts/Layout';

const Login = lazy(() => import('../pages/auth/Login'));
const PatientTracker = lazy(() => import('../pages/patient-tracker/PatientTracker'));
const PatientTrackerView = lazy(() => import('../pages/patient-tracker/PatientTrackerView'));

const SimulationCreate = lazy(() => import('../pages/simulation/simulation-create/SimulationCreate'));
const SimulationView = lazy(() => import('../pages/simulation/SimulationView'));

const Configuration = lazy(() => import('../pages/configuration'));

const TreatmentPlanningTable = lazy(() => import('../pages/treatment-planning/TreatmentPlanningTable'));
const TreatmentPlanningView = lazy(() => import('../pages/treatment-planning/TreatmentPlanningView'));
const TreatmentPlanningCreate = lazy(
  () => import('../pages/treatment-planning/treatment-planning-create/TreatmentPlanningCreate')
);

const Patient = lazy(() => import('../pages/patient/Patient'));
const PatientManualAdd = lazy(() => import('../pages/patient/PatientManualAdd'));
const PatientTable = lazy(() => import('../pages/patient/PatientTable'));

const OnTreatmentCreate = lazy(() => import('../pages/on-treatment/OnTreatmentCreate'));
const OnTreatmentView = lazy(() => import('../pages/on-treatment/OnTreatmentView'));
const OnTreatmentTable = lazy(() => import('../pages/on-treatment/OntreatmentTable'));

const EndTreatmentTable = lazy(() => import('../pages/end-treatment/EndTreatmentTable'));
const EndTreatmentCreate = lazy(() => import('../pages/end-treatment/EndTreatmentCreate'));
const EndTreatmentView = lazy(() => import('../pages/end-treatment/EndTreatmentView'));

const ChartIndex = lazy(() => import('../pages/chart/index'));
const ChartView = lazy(() => import('../pages/chart/View'));

const UserProfile = lazy(() => import('../pages/user-profile/UserProfile'));

const WorkListTable = lazy(() => import('../pages/worklist/WorkListTable'));

const ComingSoon = lazy(() => import('../pages/coming-soon/ComingSoon'));
const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'));
const SimulationCreateWithData = lazy(() => import('../pages/simulation/simulation-create/SimulationCreateWithData'));

const EmptyComponent = () => <></>;

const {
  DASHBOARD,
  WORK_LIST,
  PATIENT,
  PRE_TREATMENT,
  TREATMENT_PLANNING,
  CHART,
  ON_TREATMENT,
  END_TREATMENT,
  CONFIGURATION,
  NURSING,
  PRIOR_AUTHORIZATION,
  LOGIN,
  SIMULATION,
  SIMULATIONWITHDATA,
  USER_PROFILE,
} = UIRoutes;

export interface customRoute {
  id?: string;
  path: string;
  title: string;
  index: boolean;
  isActive: boolean;
  isDisplayInSidebar: boolean;
  component: FC;
  children: customRoute[];
}

const paths: customRoute[] = [
  {
    path: DASHBOARD,
    title: 'Dashboard',
    index: true,
    isActive: true,
    isDisplayInSidebar: true,
    component: Dashboard,
    children: [],
  },
  {
    path: WORK_LIST.INDEX,
    title: 'Worklist',
    index: true,
    isActive: false,
    isDisplayInSidebar: true,
    component: WorkListTable,
    children: [],
  },
  {
    path: PATIENT.INDEX,
    title: 'Patients',
    index: true,
    isActive: false,
    isDisplayInSidebar: true,
    component: PatientTable,
    children: [
      {
        path: PATIENT.CREATE_MANUAL,
        title: '',
        index: false,
        isActive: false,
        isDisplayInSidebar: false,
        component: PatientManualAdd,
        children: [],
      },
      {
        path: PATIENT.VIEW,
        title: '',
        index: false,
        isActive: false,
        isDisplayInSidebar: false,
        component: Patient,
        children: [],
      },
    ],
  },
  {
    path: PRE_TREATMENT.INDEX,
    title: 'Pre Treatment',
    index: true,
    isActive: false,
    isDisplayInSidebar: true,
    component: PatientTracker,
    children: [
      {
        path: PRE_TREATMENT.VIEW,
        title: '',
        index: false,
        isActive: false,
        isDisplayInSidebar: false,
        component: PatientTrackerView,
        children: [],
      },
    ],
  },
  {
    path: TREATMENT_PLANNING.INDEX,
    title: 'Treatment Planning',
    index: true,
    isActive: false,
    isDisplayInSidebar: true,
    component: TreatmentPlanningTable,
    children: [
      {
        path: TREATMENT_PLANNING.CREATE,
        title: '',
        index: false,
        isActive: false,
        isDisplayInSidebar: false,
        component: TreatmentPlanningCreate,
        children: [],
      },
      {
        path: TREATMENT_PLANNING.VIEW,
        title: '',
        index: false,
        isActive: false,
        isDisplayInSidebar: false,
        component: TreatmentPlanningView,
        children: [],
      },
    ],
  },
  {
    path: CHART.INDEX,
    title: 'Chart',
    index: true,
    isActive: false,
    isDisplayInSidebar: true,
    component: ChartIndex,
    children: [
      {
        path: CHART.VIEW,
        title: '',
        index: false,
        isActive: false,
        isDisplayInSidebar: false,
        component: ChartView,
        children: [],
      },
    ],
  },
  {
    path: ON_TREATMENT.INDEX,
    title: 'On Treatment',
    index: true,
    isActive: false,
    isDisplayInSidebar: true,
    component: OnTreatmentTable,
    children: [
      {
        path: ON_TREATMENT.CREATE,
        title: '',
        index: false,
        isActive: false,
        isDisplayInSidebar: false,
        component: OnTreatmentCreate,
        children: [],
      },
      {
        path: ON_TREATMENT.VIEW,
        title: '',
        index: false,
        isActive: false,
        isDisplayInSidebar: false,
        component: OnTreatmentView,
        children: [],
      },
    ],
  },
  {
    path: END_TREATMENT.INDEX,
    title: 'End Treatment',
    index: true,
    isActive: false,
    isDisplayInSidebar: true,
    component: EndTreatmentTable,
    children: [
      {
        path: END_TREATMENT.CREATE,
        title: '',
        index: false,
        isActive: false,
        isDisplayInSidebar: false,
        component: EndTreatmentCreate,
        children: [],
      },
      {
        path: END_TREATMENT.VIEW,
        title: '',
        index: true,
        isActive: false,
        isDisplayInSidebar: false,
        component: EndTreatmentView,
        children: [],
      },
    ],
  },
  {
    path: NURSING.INDEX,
    title: 'Nursing',
    index: true,
    isActive: false,
    isDisplayInSidebar: true,
    component: ComingSoon,
    children: [],
  },
  {
    path: PRIOR_AUTHORIZATION.INDEX,
    title: 'Prior Authorization',
    index: true,
    isActive: false,
    isDisplayInSidebar: true,
    component: ComingSoon,
    children: [],
  },
  {
    path: CONFIGURATION.INDEX,
    // path: CONFIGURATION.INDEX.concat('/', CONFIGURATION.SIMULATION_ORDER),
    title: 'Configuration',
    index: true,
    isActive: false,
    isDisplayInSidebar: true,
    component: Configuration,
    children: [
      {
        path: CONFIGURATION.SIMULATION_ORDER,
        title: '',
        index: false,
        isActive: false,
        isDisplayInSidebar: false,
        component: Configuration,
        children: [],
      },
    ],
  },
  {
    path: SIMULATION.INDEX,
    title: 'Simulation',
    index: false,
    isActive: false,
    isDisplayInSidebar: false,
    component: EmptyComponent, // reaplace with actual component
    // Component: () => <></>,
    // Component: () => null,
    children: [
      {
        path: SIMULATION.CREATE,
        title: '',
        index: true,
        isActive: false,
        isDisplayInSidebar: false,
        component: SimulationCreate,
        children: [],
      },
      {
        path: SIMULATION.CREATE,
        title: '',
        index: false,
        isActive: false,
        isDisplayInSidebar: false,
        component: SimulationCreate,
        children: [],
      },
      {
        path: SIMULATION.VIEW,
        title: '',
        index: false,
        isActive: false,
        isDisplayInSidebar: false,
        component: SimulationView,
        children: [],
      },
    ],
  },
  {
    path: USER_PROFILE.INDEX,
    title: '',
    index: true,
    isActive: false,
    isDisplayInSidebar: false,
    component: UserProfile,
    children: [],
  },
  {
    path: SIMULATIONWITHDATA.INDEX,
    title: 'WorkList',
    index: true,
    isActive: false,
    isDisplayInSidebar: false,
    component: SimulationCreateWithData,
    children: [],
  },
  {
    path: LOGIN,
    title: '',
    index: true,
    isActive: false,
    isDisplayInSidebar: false,
    component: Login,
    children: [],
  },
];

const setIdInRoutes = (arr: customRoute[], id: string): customRoute[] => {
  arr.forEach((el: customRoute, i: number) => {
    el.id = `${id}${i + 1}${Math.floor(Math.random() * 100)}`;
    if (el?.children?.length > 0) {
      setIdInRoutes(el.children, el.id);
    }
  });
  return arr;
};

export const routes = setIdInRoutes(paths, '');

// console.log('routes :>> ', routes);

const routers: ReactNode[] = [];

const getPlainPath = (path: string): string => (path.includes('//') ? path.substring(1) : path);

const getRoutePath = (route: customRoute[], key: string, parentPath: string) => {
  route.forEach(({ component: Component, id, path, children }: customRoute) => {
    const fullPath = getPlainPath(`${parentPath}/${path}`);
    routers.push(
      <Route
        key={`${key}-${id}`}
        path={fullPath}
        element={
          <Suspense fallback={<Preloader />}>
            <Component />
          </Suspense>
        }
      />
    );
    if (children?.length > 0) {
      getRoutePath(children, key, path);
    }
  });
  return routers;
};
getRoutePath(routes, 'Oncology', '');

// console.log('routers :>> ', routers);

const UserRoutes: FC = () => {
  return (
    <Routes>
      <Route path={UIRoutes.DASHBOARD} element={<Layout />}>
        {routers.map((el) => el)}
      </Route>
    </Routes>
  );
};
export default UserRoutes;
