import { MemberType } from 'shared/models';
import { albumService } from '../services';

const api = albumService.enhanceEndpoints({ addTagTypes: ['Users'] }).injectEndpoints({
  endpoints: (build) => ({
    fetchUser: build.query<MemberType[], any>({
      providesTags: ['Users'],
      query: () => ({
        url: '/members',
      }),
      extraOptions: {
        authorizationApi: false,
      },
    }),
    addMember: build.mutation<MemberType, MemberType>({
      invalidatesTags: ['Users'],
      query: (body) => ({
        url: '/members',
        method: 'POST',
        body,
      }),
      extraOptions: {
        authorizationApi: false,
      },
    }),
    deleteMember: build.mutation<object, MemberType>({
      invalidatesTags: ['Users'],
      query: (body) => ({
        url: `/members/${body.id}`,
        method: 'DELETE',
      }),
      extraOptions: {
        authorizationApi: false,
      },
    }),
  }),
});

export const { useFetchUserQuery, useAddMemberMutation, useDeleteMemberMutation } = api;
